const colors = [
    { label: "black", value: "black" },
    { label: "brown", value: "brown" },
    { label: "gray", value: "gray" },
    { label: "beige", value: "beige" },
    { label: "pink", value: "pink" },
    { label: "purple", value: "purple" },
    { label: "red", value: "red" },
    { label: "yellow", value: "yellow" },
    { label: "blue", value: "blue" },
    { label: "green", value: "green" },
    { label: "orange", value: "orange" },
    { label: "white", value: "white" },
    { label: "silver", value: "silver" },
    { label: "gold", value: "gold" },
    { label: "multi", value: "multi" },
    { label: "khaki", value: "khaki" },
    { label: "turquoise", value: "turquoise" },
    { label: "cream", value: "cream" },
    { label: "coral", value: "coral" },
    { label: "burgundy", value: "burgundy" },
    { label: "rose", value: "rose" },
    { label: "lilac", value: "lilac" },
    { label: "lightBlue", value: "light blue" },
    { label: "navy", value: "navy" },
    { label: "darkGreen", value: "dark green" },
    { label: "mustard", value: "mustard" },
    { label: "mint", value: "mint" }
];

export { colors }