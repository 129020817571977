import { links, linkSubPage } from '../links/links'

const aboutContent = {
    discover: [
        // {
        //     name: "how it works",
        //     link: links.howItWorks
        // },
        {
            name: "mobile apps",
            link: links.mobileApps
        },
        {
            name: "help center",
            link: linkSubPage.help.home
        }
    ],
    policies: [
        {
            name: "privacy policy",
            link: links.privacyPolicy
        },
        {
            name: "terms and conditions",
            link: links.termsAndConditions
        },
        {
            name: "cookies policy",
            link: links.cookiesPolicy
        }
    ],
    company: [
        {
            name: "about us",
            link: links.about
        },
        // {
        //     name: "jobs",
        //     link: links.jobs
        // },
        // {
        //     name: "press",
        //     link: links.thePress
        // },
        // {
        //     name: "advertising",
        //     link: links.advertising
        // }
    ],
    // community: [
    //     {
    //         name: "forum",
    //         link: links.forum
    //     }
    // ],
    // privacy: [
    //     {
    //         name: "privacy policy",
    //         link: links.privacyPolicy
    //     },
    //     {
    //         name: "terms and conditions",
    //         link: links.termsAndConditions
    //     },
    //     {
    //         name: "cookies policy",
    //         link: links.cookiesPolicy
    //     }
    // ],
}

export { aboutContent }
