import React from 'react'
import { connect } from 'react-redux';

// importing icons
import { CiHeart } from 'react-icons/ci'

const FavoritesOperation = ({ profile, action }) => {
    return (
        <div
            className='cursor-pointer w-[30px] flex justify-center items-center opacity-80 transition duration-200 hover:opacity-100 active:opacity-60'
            onClick={action}
        >
            {
                profile.isLoading || !profile.data ?
                    <div className="min-w-[25px] min-h-[25px] rounded-full border animate-pulse bg-gray-200"></div>
                    :
                    <CiHeart size={28} color='#3f585f' />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    };
};

export default connect(mapStateToProps, null)(FavoritesOperation);