import React from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Store from './redux/store'
import { GOOGLE_CLIENT_ID } from './config/config'

import TheBalancer from './components/TheBalancer/TheBalancer.jsx'

import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  return (

    <Provider store={Store}>

      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <div className="App">
          <TheBalancer />
        </div>
      </GoogleOAuthProvider>

      <ToastContainer />

    </Provider >
  );
}

export default App;
