import { io } from "socket.io-client";

import { API } from '../config/config';

function removeApiFromUrl(url) {
    return url.replace('/api', '');
}

const socket = io(removeApiFromUrl(API), { autoConnect: false });

export { socket }