const countryCodes = ["FR", "BE", "NL"];
const prices = [
    // FR => FR, FR => BE, FR => NL
    [3.25, 3.94, 4.04],
    [3.35, 4.46, 4.66],
    [3.75, 5.10, 5.30],
    [4.35, 6.00, 6.20],
    [6.20, 7.88, 8.08],
    [7.10, 9.56, 9.76],
    [11.15, 11.51, 11.71],
    [13.85, 14.33, 14.53],
    // BE => FR, BE => BE, BE => NL
    [3.94, 2.85, 3.94],  // Switched prices for FR & BE
    [4.46, 2.85, 4.46],
    [5.10, 2.85, 5.10],
    [6.00, 2.85, 6.00],
    [7.88, 4.10, 7.88],
    [9.56, 5.65, 9.56],
    [11.51, 9.15, 11.51],
    [14.33, 11.85, 14.33],
    // NL => FR, NL => BE, NL => NL
    [4.04, 3.94, 3.05],  // Using FR price for NL, might need adjustment
    [4.66, 4.46, 3.15],
    [5.30, 5.10, 3.25],
    [6.20, 6.00, 3.35],
    [8.08, 7.88, 6.20],
    [9.76, 9.56, 7.10],
    [11.71, 11.51, 11.15],
    [14.53, 14.33, 13.85]
];
const numericWeights = [1, 2, 3, 5, 7, 10, 15, 30];


function findClosestWeightIndex(weight) {
    for (let i = numericWeights.length - 1; i >= 0; i--) {
        if (weight >= numericWeights[i]) {
            return i;
        }
    }
    return 0;  // Return the smallest available weight if the input is smaller than any defined weight
}

function getShippingPrice(from, to, kg) {
    const fromIndex = countryCodes.indexOf(from.toUpperCase());
    const toIndex = countryCodes.indexOf(to.toUpperCase());

    // Calculate the block of rows to start from
    const blockStart = fromIndex * numericWeights.length;

    const weight = parseFloat(kg);
    const weightIndex = findClosestWeightIndex(weight);

    // Calculate the correct row by adding blockStart to weightIndex
    const rowIndex = blockStart + weightIndex;

    // Original price
    const originalPrice = parseFloat(prices[rowIndex][toIndex]);

    // Adding 25% to the original price
    const increasedPrice = originalPrice * 1.25;

    return increasedPrice.toFixed(2);
}


export { getShippingPrice }