// sizes
const clothsSizes = [
    {
        label: "0-1 Months",
        value: "0-1 Months",
    },
    {
        label: "1-3 Months",
        value: "1-3 Months",
    },
    {
        label: "3-6 Months",
        value: "3-6 Months",
    },
    {
        label: "6-9 Months",
        value: "6-9 Months",
    },
    {
        label: "9-12 Months",
        value: "9-12 Months",
    },
    {
        label: "12-24 Months",
        value: "12-24 Months",
    },
    {
        label: "24-36 Months",
        value: "24-36 Months",
    },
    {
        label: "92 cm",
        value: "92 cm",
    },
    {
        label: "98 cm",
        value: "98 cm",
    },
    {
        label: "104 cm",
        value: "104 cm",
    },
    {
        label: "110 cm",
        value: "110 cm",
    },
    {
        label: "116 cm",
        value: "116 cm",
    },
    {
        label: "122 cm",
        value: "122 cm",
    },
    {
        label: "128 cm",
        value: "128 cm",
    },
    {
        label: "134 cm",
        value: "134 cm",
    },
    {
        label: "140 cm",
        value: "140 cm",
    },
    {
        label: "146 cm",
        value: "146 cm",
    },
    {
        label: "152 cm",
        value: "152 cm",
    },
    {
        label: "158 cm",
        value: "158 cm",
    },
    {
        label: "164 cm",
        value: "164 cm",
    },
    {
        label: "170 cm",
        value: "170 cm",
    }
]

const footSizes = [
    {
        label: "18",
        value: "18",
    },
    {
        label: "19",
        value: "19",
    },
    {
        label: "20",
        value: "20",
    },
    {
        label: "21",
        value: "21",
    },
    {
        label: "22",
        value: "22",
    },
    {
        label: "23",
        value: "23",
    },
    {
        label: "24",
        value: "24",
    },
    {
        label: "25",
        value: "25",
    },
    {
        label: "26",
        value: "26",
    },
    {
        label: "27",
        value: "27",
    },
    {
        label: "28",
        value: "28",
    },
    {
        label: "29",
        value: "29",
    },
    {
        label: "30",
        value: "30",
    },
    {
        label: "31",
        value: "31",
    },
    {
        label: "32",
        value: "32",
    },
    {
        label: "33",
        value: "33",
    },
    {
        label: "34",
        value: "34",
    },
    {
        label: "35",
        value: "35",
    },
    {
        label: "36",
        value: "36",
    },
    {
        label: "37",
        value: "37",
    },
]

const gameSizes = [
    {
        label: "1-2 Years",
        value: "1-2 Years",
    },
    {
        label: "2-3 Years",
        value: "2-3 Years",
    },
    {
        label: "3-5 Years",
        value: "3-5 Years",
    },
    {
        label: "5-7 Years",
        value: "5-7 Years",
    },
    {
        label: "7-9 Years",
        value: "7-9 Years",
    },
    {
        label: "9-10 Years",
        value: "9-10 Years",
    },
    {
        label: "10-11 Years",
        value: "10-11 Years",
    },
    {
        label: "11-12 Years",
        value: "11-12 Years",
    },
    {
        label: "12-13 Years",
        value: "12-13 Years",
    },
    {
        label: "13-14 Years",
        value: "13-14 Years",
    },
    {
        label: "14-15 Years",
        value: "14-15 Years",
    },
    {
        label: "15-16 Years",
        value: "15-16 Years",
    },
    {
        label: "16-17 Years",
        value: "16-17 Years",
    },
]

export {
    clothsSizes,
    footSizes,
    gameSizes
}