import React from "react"
import { useNavigate } from 'react-router-dom'
import { TbHelpHexagon } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'

// importing utility's 
import { links, linkSubPage } from '../../../../utility'

// importing components 
import { OutlineButton, SolidButton } from '../../../../components/atoms/buttons'

const UnAuthOperations = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <>
            <div className="btn-container mr-1">
                <OutlineButton
                    title={t("header.register") + " | " + t("header.login")}
                    action={() => navigate(links.signIn)}
                />
            </div>
            <div className="btn-container mr-1">
                <SolidButton
                    title={t(`header.sell`)}
                    action={() => navigate(links.signIn)}
                />
            </div>
            <div className="btn-container mx-1">
                <div
                    className="cursor-pointer opacity-70 transition duration-300 active:opacity-60  hover:opacity-80"
                    title={t(`header.guide`)}
                    onClick={() => navigate(linkSubPage.help.home)}
                >
                    <TbHelpHexagon size={28} color='#3f585f' />
                </div>
            </div>
        </>
    )
}

export default UnAuthOperations