const toastsTypes = {
    success: "success",
    info: "info",
    warn: "warn",
    error: "error",
    pending: "pending",
    default: "default",
};

export { toastsTypes };
