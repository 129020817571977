import { timeAgo } from '../'

const getInfo = (data) => {
    // Create an array to hold the results
    const results = [];

    // Iterate over each item in the data array
    for (const item of data) {
        // Parse the timestamp into a JavaScript Date object
        const date = new Date(item.timestamp);

        // Format the date in a human-readable format (time ago)
        const formattedDate = timeAgo(date);

        // Push the formatted information to the results array
        results.push({
            region: item.location.region,
            country: item.location.country,
            city: item.location.city,
            time: formattedDate,
            timestamp: item.timestamp, // Keep original timestamp for sorting
            browser: `${item.deviceDetails.browser} ${item.deviceDetails.version}`,
        });
    }

    // Sort the results array by timestamp in descending order
    results.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    // Return the results array
    return results;
};

export { getInfo }
