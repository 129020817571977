import i18n from 'i18next';

const getBrandsForSelect = (brands) => {
    return brands.map((brand) => ({
        origin: brand.name,
        label: brand.name === 'others' ? i18n.t('brands.others') : brand.name,
        value: brand._id
    }));
};

export { getBrandsForSelect };