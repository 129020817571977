import i18n from 'i18next';

const getBrandNameById = (brands, id) => {
    const brand = brands.find(brand => brand._id === id);

    if (brand && brand.name === "others") {
        return i18n.t(`brands.${brand.name}`);
    }

    return brand ? brand.name : null;
};


export { getBrandNameById }