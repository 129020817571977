import React from 'react'

const LodingUI = ({ title, description }) => {
    return (
        <div className="min-h-screen w-full flex items-center justify-center bg-gray-100 ipad:px-2 mobile:px-2">
            <div className="p-4 bg-white shadow-lg rounded-lg">
                <div className="flex justify-center items-center flex-col space-x-3">
                    <h2 className="text-xl text-center font-semibold text-casal mb-2 ipad:text-lg mobile:text-lg">{title}</h2>
                    <p className="text-casal text-center ipad:text-sm mobile:text-sm">{description}</p>

                    <div className="w-10 h-10 mt-3 border-t-2 border-meteor rounded-full animate-spin"></div>
                </div>
            </div>
        </div>
    )
}

export default LodingUI