import { countrys } from '../'

function getCountryLabel(isoCode) {
    if (isoCode.length > 0) {
        const country = countrys.find(country => country.iso === isoCode);
        return country ? country.label : null;
    } else {
        return isoCode
    }
}

export { getCountryLabel }

