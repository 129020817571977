import {
    actionGetSubCategory,
    actionGetSubCategorySucces,
    actionGetSubCategoryFailure,
    actionUpdateSubCategory,
} from '../../actions'

const subCategoryInitialState = {
    isLoading: false,
    data: null,
    error: null
}

const subCategoryReducer = (state = subCategoryInitialState, action) => {
    switch (action.type) {
        case actionGetSubCategory: return {
            ...state,
            isLoading: true
        }
        case actionGetSubCategorySucces: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        case actionGetSubCategoryFailure: return {
            isLoading: false,
            data: null,
            error: action.payload
        }
        case actionUpdateSubCategory: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        default: return state
    }
}

export { subCategoryReducer }
