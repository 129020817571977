import {
    actionGetSettings,
    actionGetSettingsSucces,
    actionGetSettingsFailure,
    actionUpdateSettings,
    actionClearSettings,
} from '../../actions'

const settingsInitialState = {
    isLoading: false,
    data: null,
    error: null
}

const settingsReducer = (state = settingsInitialState, action) => {
    switch (action.type) {
        case actionGetSettings: return {
            ...state,
            isLoading: true
        }
        case actionGetSettingsSucces: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        case actionGetSettingsFailure: return {
            isLoading: false,
            data: null,
            error: action.payload
        }
        case actionUpdateSettings: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        case actionClearSettings: return {
            isLoading: false,
            data: null,
            error: null
        }
        default: return state
    }
}

export { settingsReducer }
