import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

import { GrFormDown } from 'react-icons/gr';
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

import { API } from '../../../../config/config'
import { links } from '../../../../utility'

import "./style.scss"

const SerachBar = () => {

    const { t } = useTranslation();
    const choicesRef = useRef(null);
    const resultRef = useRef(null);
    const navigate = useNavigate()


    const [theSearchChoice, setTheSearchChoice] = useState("articles")
    const [searchText, setSearchText] = useState("")
    const [searchResult, setsearchResult] = useState([])
    const [choicesAreVisible, setChoicesAreVisible] = useState(false);


    // close the element when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (choicesRef.current && !choicesRef.current.contains(event.target)) {
                setChoicesAreVisible(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [choicesRef]);

    // close the element when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (resultRef.current && !resultRef.current.contains(event.target)) {
                setsearchResult([])
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [resultRef]);

    const searchArticles = async () => {
        var config = {
            method: 'get',
            url: `${API}/article/v3/search?query=${searchText.trim()}&limit=20`, // Added limit as query parameter
            headers: {
                'Accept-Language': localStorage.getItem("i18nextLng").split('-')[0]
            }
        };

        await axios(config)
            .then(function (response) {
                setsearchResult(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const searchMembers = async () => {
        const config = {
            method: 'get',
            url: `${API}/profile/v3/search?username=${encodeURIComponent(searchText.trim())}&limit=20`, // Using the new endpoint and encoding the searchText
            headers: {
                'Accept-Language': localStorage.getItem("i18nextLng").split('-')[0]
            }
        };

        try {
            await axios(config)
                .then(function (response) {
                    setsearchResult(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (searchText.trim().length > 0) {
            switch (theSearchChoice) {
                case "articles":
                    searchArticles()
                    break;
                case "members":
                    searchMembers()
                    break;
                default:
                    searchMembers()
                    break;
            }
        } else {
            setsearchResult([])
        }
        // eslint-disable-next-line
    }, [searchText])


    return (
        <div className="relative w-full flex flex-nowrap justify-center align-middle">

            {/* the select input  */}
            <div
                className="cursor-pointer selectElement basis-1/12 py-1 px-1 active:bg-mercury transition-colors duration-300 capitalize flex"
                onClick={() => setChoicesAreVisible(!choicesAreVisible)}
            >
                <span className='text-sm text-casal'>{t(`header.${theSearchChoice}`)}</span>
                <span className='mt-[2px] text-casal'><GrFormDown color='#3f585f' /></span>
            </div>

            {choicesAreVisible && (
                <div ref={choicesRef} className="absolute top-[40px] z-10 left-0 cursor-pointer rounded border border-mercury selectElement basis-1/12 bg-white transition-colors duration-300 capitalize flex flex-col">
                    <span
                        className='text-sm text-casal px-1 py-[4px] hover:bg-concrete transition-[background-color] duration-300'
                        onClick={() => {
                            setChoicesAreVisible(false)
                            setTheSearchChoice("articles")
                            setSearchText('')
                        }}
                    >
                        {t(`header.articles`)}
                    </span>
                    <span
                        className='text-sm text-casal px-1 py-[4px] hover:bg-concrete transition-[background-color] duration-300'
                        onClick={() => {
                            setChoicesAreVisible(false)
                            setTheSearchChoice("members")
                            setSearchText('')
                        }}
                    >
                        {t(`header.members`)}
                    </span>
                </div>
            )}

            {/* input text serach */}
            <div className="desktops:relative small-screen:relative basis-11/12 flex py-1 px-1 border-l-2 border-mercury-bodrer">
                <span className='w-[23px] mt-[2px]'><FiSearch size={16} color='#3f585f' /></span>
                <input
                    type="text"
                    placeholder={t('header.search') + " " + t(`header.${theSearchChoice}`)}
                    className='w-[93%] bg-concrete focus:outline-none text-sm text-casal'
                    maxLength={128}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            if (searchText.trim().length > 0 && theSearchChoice === "articles") {
                                navigate(`${links.searchByText}/${searchText.trim()}`)
                                setsearchResult([])
                            }
                        }
                    }}
                />
                {
                    searchText.trim().length > 0 ?
                        <div
                            className="button-reset cursor-pointer rounded-full p-[2px] absolute right-[5px] top-[7px] opacity-100 transition-opacity duration-300 active:opacity-60"
                            onClick={() => setSearchText("")}
                        >
                            <span><AiOutlineClose size={18} color='#3f585f' /></span>
                        </div>
                        :
                        null
                }

                {/* search result display */}
                {
                    searchResult.length > 0 &&
                    (
                        <div ref={resultRef} className="resultBox w-full max-h-[300px] px-1 absolute top-[39px] right-0 flex justify-start items-start flex-col shadow-md bg-concrete z-50 border-t border-mercury-bodrer rounded-br rounded-bl overflow-y-scroll">

                            {
                                searchResult.map((item, index) => {
                                    const link = theSearchChoice === "articles"
                                        ? `${links.article}/${item.category[0].name}/${item.subCategory[0].name}/${item.title}_${item._id}`
                                        : `${links.profile}/${item.username}-${item._id}`;
                                    const text = theSearchChoice === "articles" ? item.title : item.username;

                                    return (
                                        <Link
                                            className='w-full text-casal font-bold text-sm py-1 border-b border-mercury-bodrer opacity-80 transition duration-200 hover:opacity-100'
                                            to={link}
                                            key={index}
                                            onClick={() => setsearchResult([])}
                                        >
                                            {text}
                                        </Link>
                                    );
                                })
                            }

                        </div>
                    )

                }


            </div>


        </div >
    )
}

export default SerachBar