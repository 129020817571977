import i18n from 'i18next';

const getCategorysForSelectOnlyBoyAndGirls = (originalArray) => {
    return originalArray
        .filter(item => item.name === 'girls' || item.name === 'boys')
        .map((item) => ({
            origin: item.name,
            label: i18n.t(`categories.${item.name}`),
            value: item._id,
        }));
}

export { getCategorysForSelectOnlyBoyAndGirls }
