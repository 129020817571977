import axios from 'axios'
import { API } from '../../../config/config'

const actionGetCategory = 'get_category'
const actionGetCategorySucces = 'get_category_succes'
const actionGetCategoryFailure = 'get_category_failure'
const actionUpdateCategory = 'update_category'

const getCategory = () => {
    return {
        type: actionGetCategory
    }
}

const getCategorySucces = (category) => {
    return {
        type: actionGetCategorySucces,
        payload: category
    }
}

const getCategoryFailure = (error) => {
    return {
        type: actionGetCategoryFailure,
        payload: error

    }
}

const updateCategory = (data) => {
    return {
        type: actionUpdateCategory,
        payload: data
    }
}

const fetchCategory = () => {

    return (dispatch) => {

        dispatch(getCategory())

        axios.get(`${API}/category/v3`)
            .then(response => {
                dispatch(getCategorySucces(response.data))
            })
            .catch(function (error) {
                dispatch(getCategoryFailure(error.response.data.message))
            })
    }
}

export { actionGetCategory, actionGetCategorySucces, actionGetCategoryFailure, actionUpdateCategory, getCategory, getCategorySucces, getCategoryFailure, fetchCategory, updateCategory }
