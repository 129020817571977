import React from 'react'

const SolidButton = ({ title, action, disable = false, Icon = null, isLoading = null }) => {
    return (
        <div
            className={`cursor-pointer flex justify-center items-center border-[1px] border-casal rounded py-[5px] px-[8px] bg-casal  no-active-focus ${disable || isLoading ? "cursor-default bg-casal/50 border-casal/50 transition-none" : "transition duration-300 active:opacity-70"}`}
            onClick={!disable && !isLoading ? action : null}
        >
            {
                !isLoading ?
                    <>
                        {Icon}
                        <span className='text-sm text-center text-white select-none'>{title}</span>
                    </>
                    :
                    <div className='w-full h-[20px] flex justify-center items-center'>
                        <div className="w-[13px] h-[13px] border-t-2 border-white rounded-full animate-spin"></div>
                    </div>
            }
        </div>
    )
}

export default SolidButton