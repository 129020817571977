import i18n from 'i18next';

const getSubcategoriesForSelect = (categoryId, subCategories) => {

    const filteredSubcategories = subCategories.filter(
        (subCategory) => subCategory.belongTo === categoryId
    );

    const mappedSubcategories = filteredSubcategories.sort((a, b) => {
        if (a.name === "others") return 1;
        if (b.name === "others") return -1;
        return 0;
    }).map(subCategory => {
        return {
            origin: subCategory.name,
            label: i18n.t(`subCategories.${subCategory.name}`),
            value: subCategory._id,
        }
    });

    return mappedSubcategories;
}

export { getSubcategoriesForSelect }