import { toast } from 'react-toastify';

import { toastsTypes } from './toastsTypes'

const toastify = (type, message, toastIdRef = null) => {

    const configuration = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }

    switch (type) {
        case toastsTypes.info:
            toast.info(message, configuration);
            break;
        case toastsTypes.success:
            toast.success(message, configuration);
            break;
        case toastsTypes.warn:
            toast.warn(message, configuration);
            break;
        case toastsTypes.error:
            toast.error(message, configuration);
            break;
        case toastsTypes.default:
            toast(message, configuration);
            break;
        case toastsTypes.pending:
            toastIdRef.current = toast(
                <div className='w-full flex justify-start items-center'>
                    <div className="w-[15px] h-[15px] border-t-2 border-white rounded-full animate-spin mr-1"></div>
                    <span style={{ marginLeft: '10px' }}>{message}...</span>
                </div>,
                configuration
            );
            break;
        default:
            break;
    }
}

export { toastify }
