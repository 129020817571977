import i18n from 'i18next';

const getCategorysForSelect = (originalArray) => {
    return originalArray.map((item) => ({
        origin: item.name,
        label: i18n.t(`categories.${item.name}`),
        value: item._id,
    }));
}

export { getCategorysForSelect }
