import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import { RiMenu3Line } from 'react-icons/ri'

import { socket } from '../../socket';

// import the assets
import Logo from '../../assets/images/logo/logo.svg'

// importing the sub components 
import SerachBar from './subComponents/SerachBar/SerachBar.jsx'
import UnAuthOperations from './subComponents/UnAuthOperations/UnAuthOperations.jsx'
import AuthOperationsResponsive from './subComponents/AuthOperations/AuthOperationsResponsive.jsx'
import Categorization from './subComponents/Categorization/Categorization.jsx'
import ResponsiveMenu from './subComponents/ResponsiveMenu/ResponsiveMenu.jsx'
const LangSelector = lazy(() => import("./subComponents/LangSelector/LangSelector.jsx"))
const AuthOperations = lazy(() => import('./subComponents/AuthOperations/AuthOperations.jsx'))


const Header = ({ user }) => {

    const [menuToggle, setmenuToggle] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isWebView, setIsWebView] = useState(false);

    useEffect(() => {
        setIsWebView(new URLSearchParams(window.location.search).get('fromApp') === 'true');

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    });

    // connection socket io 
    useEffect(() => {
        if (user.data) {
            socket.auth = { userId: user.data._id }
            socket.connect()
        }
        // eslint-disable-next-line
    }, [user.data])


    return (
        <>
            <header className={`fixed w-full pt-[8px] z-30 flex justify-center align-top flex-col bg-white shadow-md ipad:px-1 mobile:px-1 ${isWebView && 'hidden'}`}>
                {/* //global container for the whole header components */}

                {/* //the top area of the header which contains :
                - logo
                - the search bar 
                - the signUp/signIn buttons 
                - the guide icon 
                - and the the languages selector
                - the responsivity menu button
                */}

                <div className="mx-auto w-full flex flex-row flex-nowrap justify-between desktops:w-9/12  small-screen:px-2 bg-white">
                    {/* the logo caintainer */}
                    <Link to="/" className="relative logo-container basis-2/12 rounded">
                        <img src={Logo} alt="Ouistiti Logo" className={`absolute z-10 top-0 left-0 w-[115px] p-[5px] small-screen:w-[100px] mobile:w-[70px] ipad:w-[70px] bg-white`} />
                    </Link>

                    {/* the serach bar container */}
                    <div className="search-bar-container basis-6/12 rounded bg-concrete ipad:hidden mobile:hidden">
                        <SerachBar />
                    </div>

                    {/* operations container the signUp/signIn buttons the guide icon and the the languages selector */}
                    <div className="operations-container basis-4/12 ipad:hidden mobile:hidden">
                        <div className='w-full flex flex-row justify-end align-middle mt-[2px]'>
                            {
                                user.data && windowWidth >= 1200 ?
                                    <Suspense fallback={null}>
                                        <AuthOperations />
                                    </Suspense>
                                    :
                                    <UnAuthOperations />
                            }

                            <div className='w-[35px]'>
                                <Suspense fallback={null}>
                                    <LangSelector />
                                </Suspense>
                            </div>
                        </div>
                    </div>


                    <div className="rightSide flex justify-end items-center flex-row flex-nowrap">
                        <div className="desktops:hidden small-screen:hidden">
                            {/* operation auth */}
                            {(user.data && windowWidth < 1200) && <AuthOperationsResponsive />}
                        </div>

                        {/* the responsivity menu button container */}
                        <div
                            className="responsive-menu menuToggel basis-1/12 flex justify-end p-[5px] transition duration-200 active:opacity-60 ipad:visible mobile:visible small-screen:hidden desktops:hidden bg-white"
                            onClick={() => setmenuToggle(!menuToggle)}
                        >
                            <RiMenu3Line size={25} color='#3f585f' />
                        </div>
                    </div>


                </div>

                {/* //separation bar*/}
                <div className="absolute top-[50px] left-0 w-full h-[1px] bg-mercury z-0 "></div>


                {/* //then here stand the bottom area of the header that contains :
                -categories and subcategories
                */}
                <Categorization />

                <div className="mx-auto w-full flex flex-row flex-nowrap justify-end bg-white my-1 ipad:visible ipad:mt-[16px] mobile:visible mobile:mt-[15px] small-screen:hidden desktops:hidden">
                    {/* the serach bar respo */}
                    <div className="search-bar-container w-full rounded bg-concrete">
                        <SerachBar />
                    </div>
                </div>


                {menuToggle && <ResponsiveMenu setmenuToggle={setmenuToggle} />}

            </header>
            <Outlet />
        </>

    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Header)
