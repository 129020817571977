const links = {
    home: "/",
    help: "/help",
    articlesBy: "/articles",
    article: "/article",
    popularItems: "/popular-items",
    ShopByBrand: "/shop-by-brand",
    brands: "/brands",
    membersBy: "/members/",
    signIn: "/sign-in",
    signUp: "/sign-up",
    forgottenPassword: "/forgotten-password",
    addAnArticle: "/add-new-article",
    updateAnArticle: "/update-article",
    inbox: "/inbox",
    notifications: "/notifications",
    favouritelist: "/favourite-list",
    profile: "/profile",
    reporting: "/reporting",
    settings: "/settings",
    personalization: "/personalization",
    wallet: "/wallet",
    payment: "/payment",
    howItWorks: "/how-it-works",
    mobileApps: "/mobile-apps",
    infoBoard: "/info-board",
    forum: "/forum",
    about: "/about",
    jobs: "/jobs",
    thePress: "/the-press",
    advertising: "/advertising",
    privacyPolicy: "/privacy-policy",
    cookiesPolicy: "/cookies-policy",
    termsAndConditions: "/terms-and-conditions",
    EULA: "/EULA",
    GTCS: "/general-terms-conditions-sale-pro-sellers",
    ourPlatform: "/our-platform",
    searchByText: "/searchByText",
    viewAPayIn: "/view-a-payIn",
    shopBundles: "/shop-bundles",
    PaymentBundle: "/payment-bundle",
    followers: "/followers",
    following: "/following",
    IPBP: "/Identity-proof-best-practices",
    // 
    alert: "/Alert"
};

const linkSubPage = {
    settings: {
        profileDetails: "/settings/profile-details",
        accountSettings: "/settings/account-settings",
        shipping: "/settings/shipping",
        payments: "/settings/payments",
        bundleDiscounts: "/settings/bundle-discounts",
        donations: "/settings/donations",
        notifications: "/settings/notifications",
        privacySettings: "/settings/privacy-settings",
        security: "/settings/security",
    },
    wallet: {
        balance: "/wallet/balance",
        setup: "/wallet/setup",
        history: "/wallet/history",
        // invoices: "/wallet/invoices",
        income: "/wallet/income"
    },
    help: {
        home: "/help/home",
        // gettingStarted: "/help/getting-started",
        selling: "/help/selling",
        buying: "/help/buying",
        shipping: "/help/shipping",
        paymentsAndWithdrawals: "/help/payments-and-withdrawals",
        trustAndSafety: "/help/trust-and-safety",
        // myAccountAndSettings: "/help/my-account-and-settings",
    }
}

export { links, linkSubPage };
