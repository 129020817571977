// function that extracts all the subcategories from the subCategories array that belong to a specific categoryId
// based on the belongTo property in the subcategory objects:
const filterSubcategoriesByCategoryId = (categoryId, subCategories) => {

    const filteredSubcategories = subCategories.filter(
        (subCategory) => subCategory.belongTo === categoryId
      );
    
      return filteredSubcategories.sort((a, b) => {
        if (a.name === "others") return 1;
        if (b.name === "others") return -1;
        return 0;
      });
}

export { filterSubcategoriesByCategoryId }
