import {
    actionGetCategory,
    actionGetCategorySucces,
    actionGetCategoryFailure,
    actionUpdateCategory,
} from '../../actions'

const categoryInitialState = {
    isLoading: false,
    data: null,
    error: null
}

const categoryReducer = (state = categoryInitialState, action) => {
    switch (action.type) {
        case actionGetCategory: return {
            ...state,
            isLoading: true
        }
        case actionGetCategorySucces: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        case actionGetCategoryFailure: return {
            isLoading: false,
            data: null,
            error: action.payload
        }
        case actionUpdateCategory: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        default: return state
    }
}

export { categoryReducer }
