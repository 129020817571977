function isThePriceOffreCorrect(initialPrice, offerPrice) {
    // Calculate 40% of the initial price
    const minimumAllowed = initialPrice * 0.40;

    // Check if the offer price is greater than or equal to the 40% of the initial price
    // and not greater than the initial price (not over 100%)
    return offerPrice >= minimumAllowed && offerPrice <= initialPrice;
}

export {
    isThePriceOffreCorrect
}