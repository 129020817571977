function maskPhoneNumber(phoneNumber) {
    const regex = /(\+\d{1,3})\s*(\d+)/;
    const match = phoneNumber.match(regex);

    if (match) {
        const countryCode = match[1];
        const remainingNumber = match[2];
        const visibleDigits = 2;

        const hiddenPart = remainingNumber.slice(0, -visibleDigits).replace(/\d/g, '*');
        const lastVisibleDigits = remainingNumber.slice(-visibleDigits);

        return `${countryCode} ${hiddenPart}${lastVisibleDigits}`;
    }

    return null;
}

export { maskPhoneNumber }