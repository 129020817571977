import axios from 'axios'
import { API } from '../../../config/config'

const actionGetBrand = 'get_brand'
const actionGetBrandSucces = 'get_brand_succes'
const actionGetBrandFailure = 'get_brand_failure'
const actionUpdateBrand = 'update_brand'

const getBrand = () => {
    return {
        type: actionGetBrand
    }
}

const getBrandSucces = (brand) => {
    return {
        type: actionGetBrandSucces,
        payload: brand
    }
}

const getBrandFailure = (error) => {
    return {
        type: actionGetBrandFailure,
        payload: error

    }
}

const updateBrand = (data) => {
    return {
        type: actionUpdateBrand,
        payload: data
    }
}

const fetchBrand = () => {

    return (dispatch) => {

        dispatch(getBrand())

        axios.get(`${API}/brand/v3`)
            .then(response => {
                dispatch(getBrandSucces(response.data))
            })
            .catch(function (error) {
                dispatch(getBrandFailure(error.response.data.message))
            })
    }
}

export { actionGetBrand, actionGetBrandSucces, actionGetBrandFailure, actionUpdateBrand, getBrand, getBrandSucces, getBrandFailure, fetchBrand, updateBrand }
