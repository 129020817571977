import React from 'react'

const OutlineButtonDanger = ({ title, action, disable = false, Icon = null, isLoading = null }) => {
    return (
        <div
            className={`cursor-pointer flex justify-center items-center border-[2px] border-red-400 rounded py-[4px] px-[8px]  no-active-focus ${disable || isLoading ? "cursor-default border-red-400/70" : "transition duration-300 hover:bg-red-400/20 active:opacity-70"}`}
            onClick={!disable && !isLoading ? action : null}
        >
            {
                !isLoading ?
                    <>
                        {Icon}
                        <span className={`${Icon && "ml-[5px]"} text-sm text-center ${disable ? "text-red-400/70" : "text-red-400"} select-none`}>{title}</span>
                    </>
                    :
                    <div className='w-full h-[20px] flex justify-center items-center'>
                        <div className="w-[13px] h-[13px] border-t-2 border-red-400/50 rounded-full animate-spin"></div>
                    </div>
            }
        </div>
    )
}

export default OutlineButtonDanger