// const API = "http://192.168.100.209:4000/api"
const API = "https://loukidz-api.com/api"
// const GOOGLE_CLIENT_ID = "1017020289296-vd3pcv17njo5lluc5v46odk4bj4o559r.apps.googleusercontent.com"
// google prod
const GOOGLE_ANALYTICS_ID = "G-FTFD8LWWP5"
const GOOGLE_CLIENT_ID = "1067390250487-2lhdic2fcstj5t6uhknnj0opehnf8gda.apps.googleusercontent.com"
const FACEBOOK_CLIENT_ID = "782353239928165"

export {
    API,
    GOOGLE_CLIENT_ID,
    FACEBOOK_CLIENT_ID,
    GOOGLE_ANALYTICS_ID
}
