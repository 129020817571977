import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { fetchProfile, fetchSettings, clearProfile, clearUser, clearSettings } from '../../../../../redux/actions';
import { API } from '../../../../../config/config';
import { startsWithStatic, links, linkSubPage, axiosInstance, toastify, toastsTypes } from '../../../../../utility';
import { socket } from '../../../../../socket';

import LodingUI from '../../../../../components/organisms/loaders/LodingUI.jsx'

const ProfileOperation = ({ user, profile, settings, FetchProfile, FetchSettings, ClearProfile, ClearUser, ClearSettings }) => {

    const subRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate()

    const [subVisibe, setSubVisibe] = useState(false);
    const [isLoading, setisLoading] = useState(false)

    const subItems = [
        { name: "pages.settings", link: linkSubPage.settings.profileDetails },
        { name: "pages.wallet", link: linkSubPage.wallet.balance },
        { name: "subPage.settings.donations", link: linkSubPage.settings.donations },
    ]

    // fetching the profile for the first time
    useEffect(() => {
        if (user.data && !profile.data && !profile.isLoading && !settings.data && !settings.isLoading) {
            FetchProfile()
            FetchSettings()
        }
    }, [user, profile, settings, FetchProfile, FetchSettings]);

    // close the element when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (subRef.current && !subRef.current.contains(event.target)) {
                setSubVisibe(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [subRef]);

    // log out fucntion 
    const logOut = async () => {

        setisLoading(true)

        var config = {
            method: 'post',
            url: `/auth/v3/logout`,
            data: {
                refreshToken: localStorage.getItem('refresh_token'),
            }
        };

        await axiosInstance(config)
            .then(function () {
                // Clear access token, refresh token, and expiration date from local storage
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');


                socket.auth = { userId: user.data._id }
                socket.disconnect()

                ClearUser()
                ClearProfile()
                ClearSettings()

                // window.location.replace('/');

                setisLoading(false)
            })
            .catch(function (error) {
                // console.log(err);
                setisLoading(false)
                toastify(toastsTypes.error, `${t(`validations.${error.response.data.error.message}`)}!`)
            });
    }

    return (
        <div
            className="relative cursor-pointer w-[30px] flex justify-center items-center"
            onClick={() => setSubVisibe(!subVisibe)}
        >
            {
                profile.isLoading || !profile.data ?
                    <div className="min-w-[25px] min-h-[25px] rounded-full border animate-pulse bg-gray-200"></div>
                    :
                    <div className="min-w-[25px] min-h-[25px] rounded-full border border-casal overflow-hidden opacity-80 transition duration-200 hover:opacity-100 active:opacity-60">
                        {profile.data && <img
                            className="w-[25px] h-[25px]"
                            src={
                                startsWithStatic(profile.data.avatar)
                                    ? `${API}${profile.data.avatar}`
                                    : profile.data.avatar
                            }
                            alt="profile avatar"
                        />}
                    </div>
            }

            {
                profile.data && subVisibe && (
                    <div ref={subRef} className="absolute opacity-100 top-[40px] z-10 right-0 cursor-pointer rounded border border-mercury selectElement w-[205px] bg-white transition-colors duration-300 capitalize flex flex-col">

                        <span
                            className='text-xs text-casal uppercase mt-[5px] px-1 py-[4px] border-b-[1px] border-concrete'
                        >
                            {t(`header.account`)}
                        </span>
                        {/* { name: "pages.profile", link: links.profile }, */}

                        <span
                            className='text-sm text-casal mt-[5px] px-1 py-[4px] hover:bg-concrete transition-[background-color] duration-300'
                            onClick={() => {
                                navigate(`${links.profile}/${profile.data.username}-${profile.data._id}`)
                                setSubVisibe(false)
                            }}
                        >
                            {t(`pages.profile`)}
                        </span>

                        {
                            subItems.map((item, index) => {
                                return (
                                    <span
                                        key={index.toString()}
                                        className='text-sm text-casal mt-[5px] px-1 py-[4px] hover:bg-concrete transition-[background-color] duration-300'
                                        onClick={() => {
                                            navigate(`${item.link}`)
                                            setSubVisibe(false)
                                        }}
                                    >
                                        {t(`${item.name}`)}
                                    </span>
                                )
                            })
                        }
                        <span
                            className='text-sm text-red-400 px-1 py-[10px] border-t-[1px] border-concrete hover:bg-concrete transition-[background-color] duration-300'
                            onClick={() => {
                                setSubVisibe(false)
                                logOut()
                            }}
                        >
                            {t(`operations.logOut`)}
                        </span>
                    </div>
                )}

            {
                isLoading
                &&
                <div className='fixed top-0 left-0 w-screen h-screen'>
                    <LodingUI
                        title={t(`header.logout`)}
                        description={t(`header.logout_wait`)}
                    />
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        profile: state.profile,
        settings: state.settings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        FetchProfile: () => dispatch(fetchProfile()),
        FetchSettings: () => dispatch(fetchSettings()),
        ClearProfile: () => dispatch(clearProfile()),
        ClearSettings: () => dispatch(clearSettings()),
        ClearUser: () => dispatch(clearUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOperation);
