import React, { useEffect } from "react"
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'

// importing components 
import { ChatOperation, NotificatiosnOperation, FavoritesOperation } from './Operations'
import { fetchProfile, fetchSettings } from '../../../../redux/actions';
import { links } from '../../../../utility'

const AuthOperationsResponsive = ({ user, profile, settings, setmenuToggle = null, FetchProfile, FetchSettings }) => {

    const navigate = useNavigate();

    // fetching the profile for the first time
    useEffect(() => {
        if (user.data && !profile.data && !profile.isLoading) {
            FetchProfile()
            FetchSettings()
        }
    }, [profile, user, settings, FetchProfile, FetchSettings]);

    return (

        <div className="sections-container mr-1 flex justify-around items-center flex-row flex-nowrap">
            <div className="mr-[5px]">
                <ChatOperation
                    action={() => {
                        navigate(links.inbox)
                        setmenuToggle && setmenuToggle(false)
                    }}
                />
            </div>
            <div className="mr-[5px]">
                <NotificatiosnOperation
                    action={() => {
                        navigate(links.notifications)
                        setmenuToggle && setmenuToggle(false)
                    }}
                />
            </div>
            <div className="mr-[5px]">
                <FavoritesOperation
                    action={() => {
                        navigate(links.favouritelist)
                        setmenuToggle && setmenuToggle(false)
                    }}
                />
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        user: state.user,
        settings: state.settings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        FetchProfile: () => dispatch(fetchProfile()),
        FetchSettings: () => dispatch(fetchSettings()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthOperationsResponsive);