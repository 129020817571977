// redux react-redux redux-thunk redux-devtools-extension
import { createStore, combineReducers, applyMiddleware } from 'redux'
import ThunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import {
    preLoaderReducer,
    userReducer,
    profileReducer,
    categoryReducer,
    subCategoryReducer,
    settingsReducer,
    brandReducer
} from './reducers'

const store = createStore(combineReducers(
    {
        preLoader: preLoaderReducer,
        user: userReducer,
        profile: profileReducer,
        settings: settingsReducer,
        category: categoryReducer,
        subCategory: subCategoryReducer,
        brand: brandReducer
    }
), composeWithDevTools(applyMiddleware(ThunkMiddleware)))

export default store