const timeAgo = (dateParam) => {
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
        return { number: parseInt(secondsPast), interval: 'second' };
    }
    if (secondsPast < 3600) {
        return { number: parseInt(secondsPast / 60), interval: 'minute' };
    }
    if (secondsPast <= 86400) {
        return { number: parseInt(secondsPast / 3600), interval: 'hour' };
    }
    if (secondsPast > 86400 && secondsPast <= 2592000) { // Up to 30 days
        const days = parseInt(secondsPast / 86400);
        return { number: days, interval: 'day' };
    }
    if (secondsPast > 2592000 && secondsPast <= 31536000) { // Up to 1 year
        const months = parseInt(secondsPast / 2592000);
        return { number: months, interval: 'month' };
    }
    if (secondsPast > 31536000) { // More than 1 year
        const years = parseInt(secondsPast / 31536000);
        return { number: years, interval: 'year' };
    }
}

export { timeAgo }