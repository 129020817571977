import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { CgGirl } from 'react-icons/cg'
import { TbMoodBoy, TbHorseToy } from 'react-icons/tb'
import { BiPen, BiBook } from 'react-icons/bi'
import { BsLamp } from 'react-icons/bs'
import { MdOutlineChildFriendly } from 'react-icons/md'

// importing the function that filter sub categories based on a geving catgory id
import { filterSubcategoriesByCategoryId, links, aboutContent } from '../../../../utility'

const Categorization = ({ categories, subCategories }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activeCategory, setActiveCategory] = useState(null);

    const subRef = useRef(null);
    const toggleRef = useRef(null);

    const handleSubCategoryClick = () => {
        setActiveCategory(null);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (subRef.current && !subRef.current.contains(event.target) && toggleRef.current !== event.target) {
                setActiveCategory(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeCategory, subRef, setActiveCategory, toggleRef]);

    const GetIcon = ({ categpryName }) => {
        if (categpryName === "girls") {
            return <CgGirl size={30} color='#d37a00' />
        } else if (categpryName === "boys") {
            return <TbMoodBoy size={30} color='#d37a00' />
        } else if (categpryName === "toys") {
            return <TbHorseToy size={30} color='#d37a00' />
        } else if (categpryName === "school supplies") {
            return <BiPen size={30} color='#d37a00' />
        } else if (categpryName === "decoration") {
            return <BsLamp size={30} color='#d37a00' />
        } else if (categpryName === "books") {
            return <BiBook size={30} color='#d37a00' />
        } else if (categpryName === "childcare") {
            return <MdOutlineChildFriendly size={30} color='#d37a00' />
        }
    }

    const RenderAboutSubCategories = () => {
        return (
            <>
                {Object.keys(aboutContent).map((key) => (
                    <div key={key.toString()} className="flex justify-start items-start flex-col min-w-[200px]">
                        <span className='text-sm text-casal/60 p-[5px] ' >{t(`header.${key}`)}</span>
                        {
                            aboutContent[key].map((item, index) => {
                                return (
                                    <span
                                        key={index.toString()}
                                        className='cursor-pointer text-sm text-casal min-w-full p-[5px] rounded transition duration-300 hover:bg-concrete active:opacity-70'
                                        onClick={(event) => {
                                            event.preventDefault()
                                            handleSubCategoryClick()
                                            navigate(`${item.link}`)
                                        }}
                                    >
                                        {t(`pages.${item.name}`)}
                                    </span>
                                )
                            })
                        }
                    </div>
                ))}
            </>
        )
    }

    const RenderAbout = () => {
        return (
            <div
                className={`relative cursor-pointer min-w-[46px] px-[10px] h-[46px] flex justify-center items-center transition duration-300  ${activeCategory && activeCategory === "about" ? "bg-concrete border-b-[1px] border-casal" : null}  hover:bg-concrete`}
            >
                <div
                    onClick={() => setActiveCategory("about")}
                    className="h-full flex justify-center items-center"
                >
                    <span className='text-sm text-center text-casal'>
                        {t(`header.about`)}
                    </span>
                </div>

                <div className={`absolute top-[46px] left-0 subCategories ${activeCategory && activeCategory === "about" ? null : "hidden"}`}>
                    {
                        activeCategory === "about" && (
                            <div className='bg-white shadow-lg rounded p-1'>
                                <div className="grid grid-rows-2 grid-flow-col gap-2">
                                    <RenderAboutSubCategories />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    const RenderSubCategories = ({ categpryName, categpryID }) => {

        const items = filterSubcategoriesByCategoryId(categpryID, subCategories.data).map((item, index) => {
            return (
                <span
                    key={index.toString()}
                    className='cursor-pointer text-sm text-casal min-w-max p-[5px] rounded transition duration-300 hover:bg-concrete active:opacity-70'
                    onClick={(event) => {
                        event.preventDefault()
                        handleSubCategoryClick()
                        navigate(`${links.articlesBy}/${categpryName}/${item.name}`)
                    }}
                >
                    {t(`subCategories.${item.name}`)}
                </span>
            )
        })

        return (
            <div className='bg-white shadow-lg rounded p-1'>
                <div className="w-full h-[35px] flex justify-start items-center my-1 pb-1 border-b-[1px] border-concrete">
                    <GetIcon categpryName={categpryName} />
                    <span className='h-[25px] text-lg text-casal font-bold ml-1'>{t(`categories.${categpryName}`)}</span>
                </div>
                <div className="grid grid-rows-6 grid-flow-col gap-2">
                    <span
                        className='cursor-pointer text-sm text-casal min-w-max p-[5px] rounded transition duration-300 hover:bg-concrete active:opacity-70'
                        onClick={(event) => {
                            event.preventDefault()
                            handleSubCategoryClick()
                            navigate(`${links.articlesBy}/${categpryName}`)
                        }}
                    >
                        {t(`subCategories.all`)}
                    </span>
                    {items}
                </div>
            </div>
        )
    }

    const RenderCategories = () => {

        const items = categories.data.map((item, index) => {
            return (
                <div
                    key={index.toString()}
                    className={`relative cursor-pointer min-w-[46px] px-[10px] h-[46px] flex justify-center items-center transition duration-300  ${activeCategory && activeCategory === item._id ? "bg-concrete border-b-[1px] border-casal" : null}  hover:bg-concrete`}
                >
                    <div
                        onClick={() => setActiveCategory(item._id)}
                        className="h-full flex justify-center items-center"
                    >
                        <span

                            className='text-sm text-center text-casal'>{t(`categories.${item.name}`)}
                        </span>
                    </div>

                    <div className={`absolute top-[46px] left-0 subCategories ${activeCategory && activeCategory === item._id ? null : "hidden"}`}>
                        {
                            activeCategory && (
                                <RenderSubCategories categpryName={item.name} categpryID={item._id} />
                            )
                        }
                    </div>
                </div>
            )
        })

        return (
            <div className="w-11/12 pl-[55px] flex flex-row flex-nowrap justify-start">
                {items}
                <RenderAbout />
            </div>
        )
    }

    return (
        <div ref={subRef} className='mx-auto w-full flex flex-row flex-nowrap justify-end mt-[5px] ipad:hidden mobile:hidden  desktops:w-9/12  small-screen:px-2 bg-white'>
            <RenderCategories />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        categories: state.category,
        subCategories: state.subCategory
    }
}

export default connect(mapStateToProps, null)(Categorization)
