import React, { useState, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { CgGirl } from 'react-icons/cg'
import { TbMoodBoy, TbHorseToy } from 'react-icons/tb'
import { BiPen, BiBook } from 'react-icons/bi'
import { BsLamp } from 'react-icons/bs'
import { MdOutlineChildFriendly } from 'react-icons/md'
import { TfiClose } from 'react-icons/tfi'

import { links, linkSubPage, aboutContent, axiosInstance, toastify, toastsTypes } from '../../../../utility'
import { clearProfile, clearUser, clearSettings } from '../../../../redux/actions';

// import the assets
import Logo from '../../../../assets/images/logo/logo.svg'

// importing custom css
import "./style.scss"

// importing components
import UnAuthOperationsResponsive from '../UnAuthOperations/UnAuthOperationsResponsive.jsx'
import AuthOperationsResponsive from '../AuthOperations/AuthOperationsResponsive.jsx'
import LodingUI from '../../../../components/organisms/loaders/LodingUI.jsx'
const LangSelectorResponsive = lazy(() => import("../LangSelector/LangSelectorResponsive.jsx"))


const ResponsiveMenu = ({ categories, user, profile, setmenuToggle, ClearProfile, ClearSettings, ClearUser }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setisLoading] = useState(false)

    const subItems = [
        { name: "pages.settings", link: linkSubPage.settings.profileDetails },
        { name: "pages.wallet", link: linkSubPage.wallet.balance },
        { name: "subPage.settings.donations", link: linkSubPage.settings.donations },
    ]

    const GetIcon = ({ categpryName }) => {
        if (categpryName === "girls") {
            return <CgGirl size={28} color='#d37a00' />
        } else if (categpryName === "boys") {
            return <TbMoodBoy size={28} color='#d37a00' />
        } else if (categpryName === "toys") {
            return <TbHorseToy size={28} color='#d37a00' />
        } else if (categpryName === "school supplies") {
            return <BiPen size={28} color='#d37a00' />
        } else if (categpryName === "decoration") {
            return <BsLamp size={28} color='#d37a00' />
        } else if (categpryName === "books") {
            return <BiBook size={28} color='#d37a00' />
        } else if (categpryName === "childcare") {
            return <MdOutlineChildFriendly size={28} color='#d37a00' />
        }
    }

    const RenderCategories = () => {

        const items = categories.data.map((item, index) => {
            return (

                <li
                    key={index.toString()}
                    className="cursor-pointer w-full h-[35px] flex justify-start items-center my-3 pb-1 border-b-[1px] border-concrete transition duration-200 active:opacity-25 no-active-focus"
                    onClick={() => {
                        navigate(`${links.articlesBy}/${item.name}`)
                        setmenuToggle(false)
                    }}
                >
                    <GetIcon categpryName={item.name} />
                    <span className='text-lg text-casal ml-1'>{t(`categories.${item.name}`)}</span>
                </li>

            )
        })

        return <ul className="list-none w-full">{items}</ul>
    }

    // log out fucntion 
    const logOut = async () => {
        setisLoading(true)

        var config = {
            method: 'post',
            url: `/auth/v3/logout`,
            data: {
                refreshToken: localStorage.getItem('refresh_token'),
            }
        };

        await axiosInstance(config)
            .then(function () {
                // Clear access token, refresh token, and expiration date from local storage
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                ClearUser()
                ClearProfile()
                ClearSettings()

                // window.location.replace('/');

                setisLoading(false)
            })
            .catch(function (error) {
                // console.log(err);
                setisLoading(false)
                toastify(toastsTypes.error, `${t(`validations.${error.response.data.error.message}`)}!`)
            });
    }

    return (

        <div
            className="menu fixed top-0 left-0 z-50 w-full h-screen bg-white p-1 pt-[60px] desktops:hidden small-screen:hidden"
        >
            <div className="content w-full overflow-y-scroll h-full flex flex-col justify-start items-start bg-white">
                {/*  */}
                {/* logo an close button container */}
                <div className="logo-and-closer fixed top-0 left-0 w-full flex p-1 flex-row flex-nowrap justify-between shadow-md items-center bg-white ">
                    <img
                        src={Logo} alt="ouistiti logo"
                        className=' w-[70px]'
                        onClick={() => {
                            navigate(`${links.home}`)
                            setmenuToggle(false)
                        }}
                    />
                    <div className="rightSide flex justify-end items-center flex-row flex-nowrap">
                        {user.data && <AuthOperationsResponsive setmenuToggle={setmenuToggle} />}
                        <div
                            className='close active:opacity-60 h-[60px] flex justify-center items-start pt-[20px] ml-1'
                            onClick={() => setmenuToggle(false)}
                        >
                            <TfiClose size={20} color='#3f585f' />
                        </div>
                    </div>

                </div>

                {/* unAuth operations component */}
                <div className="w-full flex justify-center items-center mt-2">
                    <UnAuthOperationsResponsive setmenuToggle={setmenuToggle} />
                </div>

                {/* categories list */}
                <div className="categories-list w-full flex justify-start items-start flex-col bg-white py-1 pt-[20px]">
                    <span className='text-sm text-casal/60' >{t(`header.Browse`)}</span>
                    <RenderCategories />
                </div>

                {/* account list */}
                {user.data &&
                    <div className="categories-list w-full flex justify-start items-start flex-col bg-white py-1 pt-[20px]">
                        <span className='text-sm text-casal/60 mb-1 capitalize' >{t(`header.account`)}</span>

                        <span
                            className='cursor-pointer capitalize w-full text-base text-casal min-w-max py-[10px] rounded transition duration-300 hover:bg-concrete active:opacity-70 no-active-focus'
                            onClick={() => {
                                navigate(`${links.profile}/${profile.data.username}-${profile.data._id}`)
                                setmenuToggle(false)
                            }}
                        >
                            {t(`pages.profile`)}
                        </span>

                        {
                            subItems.map((item, index) => {
                                return (
                                    <span
                                        key={index.toString()}
                                        className='cursor-pointer capitalize w-full text-base text-casal min-w-max py-[10px] rounded transition duration-300 hover:bg-concrete active:opacity-70 no-active-focus'
                                        onClick={() => {
                                            navigate(`${item.link}`)
                                            setmenuToggle(false)
                                        }}
                                    >
                                        {t(`${item.name}`)}
                                    </span>
                                )
                            })
                        }

                        <span
                            className='cursor-pointer capitalize w-full text-base text-red-400 min-w-max py-[10px] rounded transition duration-300 hover:bg-concrete active:opacity-70 no-active-focus'
                            onClick={() => {
                                setmenuToggle(false)
                                logOut()
                            }}
                        >
                            {t(`operations.logOut`)}
                        </span>
                    </div>
                }


                {/* lang component */}
                <div className="categories-list w-full flex justify-start items-start flex-col bg-white py-[20px]  border-b-2 border-concrete">
                    <span className='text-sm text-casal/60 mb-2' >{t(`header.language`)}</span>
                    <Suspense fallback={null}>
                        <LangSelectorResponsive />
                    </Suspense>
                </div>

                {Object.keys(aboutContent).map((key) => (
                    <div
                        key={key.toString()}
                        className="categories-list w-full flex justify-start items-start flex-col bg-white py-[20px]  border-b-2 border-concrete"
                    >
                        <div className="flex justify-start items-start flex-col w-full">
                            <span className='text-sm text-casal/60 py-[5px] ' >{t(`header.${key}`)}</span>
                            {
                                aboutContent[key].map((item, index) => {
                                    return (
                                        <span
                                            key={index.toString()}
                                            className='cursor-pointer w-full text-base text-casal min-w-max py-[10px] rounded transition duration-300 hover:bg-concrete active:opacity-70 no-active-focus'
                                            onClick={() => {
                                                navigate(`${item.link}`)
                                                setmenuToggle(false)
                                            }}
                                        >
                                            {t(`pages.${item.name}`)}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                ))}



            </div>

            {
                isLoading
                &&
                <div className='fixed top-0 left-0 w-screen h-screen'>
                    <LodingUI
                        title={t(`header.logout`)}
                        description={t(`header.logout_wait`)}
                    />
                </div>
            }
        </div>

    )
}

const mapStateToProps = state => {
    return {
        categories: state.category,
        user: state.user,
        profile: state.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ClearProfile: () => dispatch(clearProfile()),
        ClearUser: () => dispatch(clearUser()),
        ClearSettings: () => dispatch(clearSettings()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveMenu)

