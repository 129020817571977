function stringToDate(str) {
    const [day, monthName, year] = str.split("/");
    const monthNames = {
        "January": 0,
        "February": 1,
        "March": 2,
        "April": 3,
        "May": 4,
        "June": 5,
        "July": 6,
        "August": 7,
        "September": 8,
        "October": 9,
        "November": 10,
        "December": 11
    };
    const month = monthNames[monthName];
    return new Date(year, month, day);
}

export {
    stringToDate
}