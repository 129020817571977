function unixTimestampToHumanTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds by multiplying by 1000

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based index
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
}

export { unixTimestampToHumanTime }