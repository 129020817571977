import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TheHelmet = ({ title, description, toIndex = false, children }) => {
    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                {toIndex && <meta name="robots" content="index" />}
            </Helmet>
            {children}
        </HelmetProvider>
    )
}

export default TheHelmet