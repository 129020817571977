function formatCardNumber(cardNumber) {
    if (!cardNumber) return '**** **** **** ****';

    // Fill the card number with asterisks to ensure it has 16 characters.
    let fullCardNumber = (cardNumber + '****************').slice(0, 16);

    // Insert spaces every 4 characters.
    return fullCardNumber.replace(/(.{4})(?=.)/g, '$1 ');
}

function formatExpiryDate(expiry) {
    if (!expiry || expiry.length < 2) return '** / **';

    // Split month and year
    let [month, year] = expiry.split('/');

    // If month or year is not fully provided, mask with asterisks
    month = month ? month.padEnd(2, '*') : '**';
    year = year ? year.padEnd(2, '*') : '**';

    return `${month} / ${year}`;
}

export { formatCardNumber, formatExpiryDate };
