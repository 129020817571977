import React from "react"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';

// importing utility's 
import { links } from '../../../../utility'

// importing components 
import { OutlineButton, SolidButton } from '../../../../components/atoms/buttons'


const UnAuthOperationsResponsive = ({ setmenuToggle, user }) => {

    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <div className='w-full flex flex-col justify-center items-center py-1 pt-[15px] border-b-2 border-concrete'>

            {!user.data &&
                <div className="btn-container my-[5px] w-full">
                    <OutlineButton
                        title={t("header.register") + " | " + t("header.login")}
                        action={() => {
                            navigate(links.signIn)
                            setmenuToggle(false)
                        }}
                    />
                </div>
            }

            <div className="btn-container my-[5px] w-full">
                <SolidButton
                    title={t(`header.sell`)}
                    action={() => {
                        navigate(user.data ? links.addAnArticle : links.signIn)
                        setmenuToggle(false)
                    }}
                />
            </div>
            <div className="btn-container my-[5px] w-full">
                <div
                    className="cursor-pointer flex justify-center items-center transition duration-300 active:opacity-60 py-[5px] px-[8px] bg-white no-active-focus"
                    onClick={() => {
                        navigate(links.help)
                        setmenuToggle(false)
                    }}
                >
                    <span className="text-sm text-center font-bold text-casal">{t("header.Your Ouistiti guide")}</span>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(UnAuthOperationsResponsive);
