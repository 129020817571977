import React, { lazy, Suspense } from "react"
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from "react-ga4"
import { useTranslation } from 'react-i18next';

import Header from '../../layouts/header/TheHeader.jsx'
import Footer from '../../layouts/Footer/Footer.jsx'
import TheHelmet from './TheHelmet.jsx'

// pages importing
const PreLoader = lazy(() => import("../../pages/preloader/PreLoader.jsx"))
const Home = lazy(() => import("../../pages/home/Home.jsx"))
const Help = React.lazy(() => import('../../pages/Help/Help.jsx'))
const ArticlesBy = React.lazy(() => import('../../pages/ArticlesBy/ArticlesBy.jsx'))
const Article = React.lazy(() => import('../../pages/Article/Article.jsx'))
const PopularArticle = React.lazy(() => import('../../pages/PopularArticle/PopularArticle.jsx'))
const SearchByText = React.lazy(() => import('../../pages/searchByText/SearchByText.jsx'))
const ShopByBrand = React.lazy(() => import('../../pages/ShopByBrand/ShopByBrand.jsx'))
const SignIn = React.lazy(() => import('../../pages/SignIn/SignIn.jsx'))
const SignUp = React.lazy(() => import('../../pages/SignUp/SignUp.jsx'))
const ForgottenPassword = React.lazy(() => import('../../pages/ForgottenPassword/ForgottenPassword.jsx'))
const AccountConfirmation = React.lazy(() => import('../../pages/accountConfirmation/AccountConfirmation.jsx'))
const AddaNewArticle = React.lazy(() => import('../../pages/AddaNewArticle/AddaNewArticle.jsx'))
const UpdateArticle = React.lazy(() => import('../../pages/updateaArticle/updateArticle.jsx'))
const Inbox = React.lazy(() => import('../../pages/Inbox/Inbox.jsx'))
const Notifications = React.lazy(() => import('../../pages/Notifications/Notifications.jsx'))
const Favouritelist = React.lazy(() => import('../../pages/Favouritelist/Favouritelist.jsx'))
const Profile = React.lazy(() => import('../../pages/Profile/Profile.jsx'))
const Settings = React.lazy(() => import('../../pages/Settings/Settings.jsx'))
const Wallet = React.lazy(() => import('../../pages/Wallet/Wallet.jsx'))
const Payment = React.lazy(() => import('../../pages/Payment/Payment.jsx'))
const PaymentBundle = React.lazy(() => import('../../pages/PaymentBundle/PaymentBundle.jsx'))
const MobileApps = React.lazy(() => import('../../pages/MobileApps/MobileApps.jsx'))
const About = React.lazy(() => import('../../pages/About/About.jsx'))
const PrivacyPolicy = React.lazy(() => import('../../pages/PrivacyPolicy/PrivacyPolicy.jsx'))
const CookiesPolicy = React.lazy(() => import('../../pages/CookiesPolicy/CookiesPolicy.jsx'))
const TermsAndConditions = React.lazy(() => import('../../pages/TermsAndConditions/TermsAndConditions.jsx'))
const EULA = React.lazy(() => import('../../pages/EULA/EULA.jsx'))
const PSPMANGOPAY = React.lazy(() => import('../../pages/PSP_MANGOPAY/PSPMANGOPAY.jsx'))
const Alert = React.lazy(() => import('../../pages/Alert/Alert.jsx'))
const BundleSelecction = React.lazy(() => import('../../pages/BundleSelecction/BundleSelecction.jsx'))
const Followers = React.lazy(() => import('../../pages/followers/followers.jsx'))
const Following = React.lazy(() => import('../../pages/Following/Following.jsx'))
const IPBP = React.lazy(() => import('../../pages/IPBP/IPBP.jsx'))
const PageNotFound = React.lazy(() => import('../../pages/404/PageNotFound.jsx'))


const TheBalancer = ({ preLoader, user }) => {

    ReactGA.initialize("G-FTFD8LWWP5")

    const { t } = useTranslation();


    const RenderPublicPage = ({ Page, title, description, toIndex = true, useHelmet = true }) => {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

        if (useHelmet) {
            return (
                <TheHelmet
                    title={title}
                    description={description}
                    toIndex={toIndex}
                >
                    <Suspense fallback={<div className="w-screen h-screen"></div>} >
                        <Page />
                    </Suspense>
                </TheHelmet>
            )
        }

        return (
            <Suspense fallback={<div className="w-screen h-screen"></div>} >
                <Page />
            </Suspense>
        )

    }

    const RenderPrivatePage = ({ Page, title, description, toIndex = true, useHelmet = true }) => {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

        if (user.data) {

            if (useHelmet) {
                return (
                    <TheHelmet
                        title={title}
                        description={description}
                        toIndex={toIndex}
                    >
                        <Suspense fallback={<div className="w-screen h-screen"></div>} >
                            <Page />
                        </Suspense>
                    </TheHelmet>
                )
            }

            return (
                <Suspense fallback={<div className="w-screen h-screen"></div>} >
                    <Page />
                </Suspense>
            )

        } else {
            return <Navigate to="/sign-in" />;
        }
    }

    const RenderUnAuthPage = ({ Page, title, description, toIndex = true, useHelmet = true }) => {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

        if (!user.data) {

            if (useHelmet) {
                return (
                    <TheHelmet
                        title={title}
                        description={description}
                        toIndex={toIndex}
                    >
                        <Suspense fallback={<div className="w-screen h-screen"></div>} >
                            <Page />
                        </Suspense>
                    </TheHelmet>
                )
            }

            return (
                <Suspense fallback={<div className="w-screen h-screen"></div>} >
                    <Page />
                </Suspense>
            )

        } else {
            return <Navigate to="/" />;
        }
    }

    return (
        <>
            {
                preLoader.active ?
                    <PreLoader />
                    :
                    <BrowserRouter>
                        <Header />
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={
                                    <RenderPublicPage
                                        Page={Home}
                                        title={t("meta.Home.title")}
                                        description={t("meta.Home.description")}
                                    />
                                }
                            />
                            <Route
                                path="/articles/:category/:subCategory?"
                                element={
                                    <RenderPublicPage
                                        Page={ArticlesBy}
                                        useHelmet={false}
                                    />
                                }
                            />
                            <Route
                                path="/article/:category/:subCategory/:id"
                                element={
                                    <RenderPublicPage
                                        Page={Article}
                                        useHelmet={false}
                                    />
                                }
                            />
                            <Route
                                path="/shop-bundles/:userId"
                                element={
                                    <RenderPrivatePage
                                        Page={BundleSelecction}
                                        title={t("meta.BundleSelection.title")}
                                        description={t("meta.BundleSelection.description")}
                                    />
                                }
                            />
                            <Route
                                path="/popular-items"
                                element={
                                    <RenderPublicPage
                                        Page={PopularArticle}
                                        title={t("meta.PopularArticle.title")}
                                        description={t("meta.PopularArticle.description")}
                                    />
                                }
                            />
                            <Route
                                path="/searchByText/:text"
                                element={
                                    <RenderPublicPage
                                        Page={SearchByText}
                                        title={t("meta.TextSearch.title")}
                                        description={t("meta.TextSearch.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/shop-by-brand/:theBrand"
                                element={
                                    <RenderPublicPage
                                        Page={ShopByBrand}
                                        title={t("meta.ShopByBrand.title")}
                                        description={t("meta.ShopByBrand.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/sign-in"
                                element={
                                    <RenderUnAuthPage
                                        Page={SignIn}
                                        title={t("meta.Login.title")}
                                        description={t("meta.Login.description")}
                                    />
                                }
                            />
                            <Route
                                path="/sign-up"
                                element={
                                    <RenderUnAuthPage
                                        Page={SignUp}
                                        title={t("meta.SignUp.title")}
                                        description={t("meta.SignUp.description")}
                                    />
                                }
                            />
                            <Route
                                path="/forgotten-password"
                                element={
                                    <RenderUnAuthPage
                                        Page={ForgottenPassword}
                                        title={t("meta.ForgotPassword.title")}
                                        description={t("meta.ForgotPassword.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/account-confirmation/:lang?/:fromApp?/:token"
                                element={
                                    <RenderPublicPage
                                        Page={AccountConfirmation}
                                        title={t("meta.AccountConfirmation.title")}
                                        description={t("meta.AccountConfirmation.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/add-new-article"
                                element={
                                    <RenderPrivatePage
                                        Page={AddaNewArticle}
                                        title={t("meta.AddNewArticle.title")}
                                        description={t("meta.AddNewArticle.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/update-article/:id"
                                element={
                                    <RenderPrivatePage
                                        Page={UpdateArticle}
                                        title={t("meta.UpdateArticle.title")}
                                        description={t("meta.UpdateArticle.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/inbox/:param?"
                                element={
                                    <RenderPrivatePage
                                        Page={Inbox}
                                        title={t("meta.Inbox.title")}
                                        description={t("meta.Inbox.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/notifications"
                                element={
                                    <RenderPrivatePage
                                        Page={Notifications}
                                        title={t("meta.Notifications.title")}
                                        description={t("meta.Notifications.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/Alert/:type/:id"
                                element={
                                    <RenderPrivatePage
                                        Page={Alert}
                                        title={t("meta.Report.title")}
                                        description={t("meta.Report.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/favourite-list"
                                element={
                                    <RenderPrivatePage
                                        Page={Favouritelist}
                                        title={t("meta.FavoritesList.title")}
                                        description={t("meta.FavoritesList.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/profile/:id"
                                element={
                                    <RenderPublicPage
                                        Page={Profile}
                                        useHelmet={false}
                                    />
                                }
                            />
                            <Route
                                path="/settings/:subPage"
                                element={
                                    <RenderPrivatePage
                                        Page={Settings}
                                        title={t("meta.AccountSettings.title")}
                                        description={t("meta.AccountSettings.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/wallet/:subPage"
                                element={
                                    <RenderPrivatePage
                                        Page={Wallet}
                                        title={t("meta.Wallet.title")}
                                        description={t("meta.Wallet.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/payment/:id"
                                element={
                                    <RenderPrivatePage
                                        Page={Payment}
                                        title={t("meta.Payment.title")}
                                        description={t("meta.Payment.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/payment-bundle/:id"
                                element={
                                    <RenderPrivatePage
                                        Page={PaymentBundle}
                                        title={t("meta.BundlePayment.title")}
                                        description={t("meta.BundlePayment.description")}
                                        toIndex={false}
                                    />
                                }
                            />
                            <Route
                                path="/mobile-apps"
                                element={
                                    <RenderPublicPage
                                        Page={MobileApps}
                                        title={t("meta.MobileApps.title")}
                                        description={t("meta.MobileApps.description")}
                                    />
                                }
                            />
                            <Route
                                path="/PSPMANGOPAY"
                                element={
                                    <RenderPublicPage
                                        Page={PSPMANGOPAY}
                                        title={t("meta.PSPMANGOPAY.title")}
                                        description={t("meta.PSPMANGOPAY.description")}
                                    />
                                }
                            />
                            <Route
                                path="/help/:subPage"
                                element={
                                    <RenderPublicPage
                                        Page={Help}
                                        title={t("meta.Help.title")}
                                        description={t("meta.Help.description")}
                                    />
                                }
                            />
                            <Route
                                path="/about"
                                element={
                                    <RenderPublicPage
                                        Page={About}
                                        title={t("meta.AboutUs.title")}
                                        description={t("meta.AboutUs.description")}
                                    />
                                }
                            />
                            <Route
                                path="/privacy-policy"
                                element={
                                    <RenderPublicPage
                                        Page={PrivacyPolicy}
                                        title={t("meta.PrivacyPolicy.title")}
                                        description={t("meta.PrivacyPolicy.description")}
                                    />
                                }
                            />
                            <Route
                                path="/cookies-policy"
                                element={
                                    <RenderPublicPage
                                        Page={CookiesPolicy}
                                        title={t("meta.CookiePolicy.title")}
                                        description={t("meta.CookiePolicy.description")}
                                    />
                                }
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={
                                    <RenderPublicPage
                                        Page={TermsAndConditions}
                                        title={t("meta.TermsAndConditions.title")}
                                        description={t("meta.TermsAndConditions.description")}
                                    />
                                }
                            />
                            <Route
                                path="/EULA"
                                element={
                                    <RenderPublicPage
                                        Page={EULA}
                                        title={t("meta.EULA.title")}
                                        description={t("meta.EULA.description")}
                                    />
                                }
                            />
                            <Route
                                path="/followers/:id"
                                element={
                                    <RenderPublicPage
                                        Page={Followers}
                                        title={t("meta.Followers.title")}
                                        description={t("meta.Followers.description")}
                                    />
                                }
                            />
                            <Route
                                path="/following/:id"
                                element={
                                    <RenderPublicPage
                                        Page={Following}
                                        title={t("meta.Following.title")}
                                        description={t("meta.Following.description")}
                                    />
                                }
                            />
                            <Route
                                path="/Identity-proof-best-practices"
                                element={
                                    <RenderPublicPage
                                        Page={IPBP}
                                        title={t("meta.IdentityProofBestPractices.title")}
                                        description={t("meta.IdentityProofBestPractices.description")}
                                    />
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <RenderPublicPage
                                        Page={PageNotFound}
                                        title={t("meta.PageNotFound.title")}
                                        description={t("meta.PageNotFound.description")}
                                    />
                                }
                            />
                        </Routes>
                        <Footer />
                    </BrowserRouter>
            }


        </>

    )
}

const mapStateToProps = state => {
    return {
        preLoader: state.preLoader,
        user: state.user
    }
}

export default connect(mapStateToProps, null)(TheBalancer)
