const isProfileValid = (theProfile) => {
    // Check if the required fields are present and non-empty
    if (
        !theProfile.contact ||
        !theProfile.contact.address1 ||
        theProfile.contact.address1.trim().length === 0 ||
        !theProfile.location ||
        (!theProfile.location.city || theProfile.location.city.trim().length === 0) ||
        !theProfile.personalInfo ||
        (!theProfile.personalInfo.firstName || theProfile.personalInfo.firstName.trim().length === 0) ||
        (!theProfile.personalInfo.lastName || theProfile.personalInfo.lastName.trim().length === 0) ||
        (!theProfile.personalInfo.nationality || theProfile.personalInfo.nationality.trim().length === 0) ||  // Added check for nationality
        (!theProfile.email || theProfile.email.trim().length === 0) ||
        (!theProfile.location.country || theProfile.location.country.trim().length === 0) ||
        (!theProfile.location.zipCode || theProfile.location.zipCode.trim().length === 0)
    ) {
        return false;
    }

    // Check if the birthday is a valid date
    if (!theProfile.personalInfo.birthday || !(new Date(theProfile.personalInfo.birthday) instanceof Date) || isNaN(new Date(theProfile.personalInfo.birthday))) {
        return false;
    }

    // Check if the gender is present and non-empty
    if (!theProfile.personalInfo.gender || theProfile.personalInfo.gender.trim().length === 0) {
        return false;
    }

    return true;  // Profile is valid
};

export { isProfileValid }
