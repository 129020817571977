import {
    actionGetBrand,
    actionGetBrandSucces,
    actionGetBrandFailure,
    actionUpdateBrand,
} from '../../actions'

const brandInitialState = {
    isLoading: false,
    data: null,
    error: null
}

const brandReducer = (state = brandInitialState, action) => {
    switch (action.type) {
        case actionGetBrand: return {
            ...state,
            isLoading: true
        }
        case actionGetBrandSucces: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        case actionGetBrandFailure: return {
            isLoading: false,
            data: null,
            error: action.payload
        }
        case actionUpdateBrand: return {
            isLoading: false,
            data: action.payload,
            error: null
        }
        default: return state
    }
}

export { brandReducer }
