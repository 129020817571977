import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BsChatDots } from 'react-icons/bs'

import { socket } from '../../../../../socket'
import { toastify, toastsTypes, axiosInstance } from '../../../../../utility'


const ChatOperation = ({ profile, action }) => {

  const { t } = useTranslation()

  // eslint-disable-next-line
  const [unreadedMessages, setunreadedMessages] = useState(0)

  const handleNotification = (payload) => {
    // Check if the current URL contains the word "inbox"
    if (!window.location.href.includes("inbox")) {
      setunreadedMessages(prev => prev + 1)
      toastify(toastsTypes.info, `${t('notifications.new_message_from')} ${payload.userName}`)
    }
  }

  // fetch the unreaded notifs
  const fetchUnreadedMessages = async () => {
    var config = {
      method: 'get',
      url: `/chat/v3/unreadMessagesCount`,
    };

    await axiosInstance(config)
      .then(function (response) {
        setunreadedMessages(response.data.unreadCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    socket.on("receive_a_private_message", handleNotification);

    fetchUnreadedMessages()

    return () => {
      socket.off("receive_a_private_message", handleNotification);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className='cursor-pointer w-[30px] flex justify-center items-center opacity-80 transition duration-200 hover:opacity-100 active:opacity-60'
      onClick={() => {
        setunreadedMessages(0)
        action()
      }}
    >
      {
        profile.isLoading || !profile.data ?
          <div className="min-w-[25px] min-h-[25px] rounded-full border animate-pulse bg-gray-200"></div>
          :
          <div className="relative">
            {unreadedMessages > 0 && (
              <div className="absolute top-[-5px] left-[10px] min-w-[15px] min-h-[15px] flex justify-center items-start bg-red-500 rounded-md p-[2px]">
                <span className='font-bold text-xs text-white'>{unreadedMessages}</span>
              </div>
            )}
            <BsChatDots size={22} color='#3f585f' />
          </div>

      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, null)(ChatOperation);