const countrys = [
    {
        iso: "BE",
        label: "Belgium",
        value: "BE",
        nigative: "+32"
    },
    {
        iso: "FR",
        label: "France",
        value: "FR",
        nigative: "+33"
    },
    {
        iso: "NL",
        label: "Netherlands",
        value: "NL",
        nigative: "+31"
    }
]

export {
    countrys
}