function monthsSinceWithTimestamps(dateStr) {
    const monthNames = {
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December"
    };

    const givenDate = new Date(dateStr);
    const currentDate = new Date();

    let monthsArray = [];
    let month = givenDate.getMonth();
    let year = givenDate.getFullYear();

    while (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month <= currentDate.getMonth())) {
        const startOfMonth = new Date(year, month, 1, 0, 0, 0);
        const endOfMonth = new Date(year, month + 1, 0, 23, 59, 59); // 0th day of next month is the last day of current month

        monthsArray.push({
            year: year,
            monthName: monthNames[new Date(year, month).toLocaleString('default', { month: 'long' })],
            startTimestamp: Math.floor(startOfMonth.getTime() / 1000),
            endTimestamp: Math.floor(endOfMonth.getTime() / 1000)
        });

        month++;
        if (month > 11) {
            month = 0;
            year++;
        }
    }

    return monthsArray.reverse();
}

export {
    monthsSinceWithTimestamps
}