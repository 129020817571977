import axios from 'axios'
import { API } from '../../../config/config'

const actionGetSubCategory = 'get_subcategory'
const actionGetSubCategorySucces = 'get_subCategory_succes'
const actionGetSubCategoryFailure = 'get_subCategory_failure'
const actionUpdateSubCategory = 'update_subCategory'

const getSubCategory = () => {
    return {
        type: actionGetSubCategory
    }
}

const getSubCategorySucces = (category) => {
    return {
        type: actionGetSubCategorySucces,
        payload: category
    }
}

const getSubCategoryFailure = (error) => {
    return {
        type: actionGetSubCategoryFailure,
        payload: error

    }
}

const updateSubCategory = (data) => {
    return {
        type: actionUpdateSubCategory,
        payload: data
    }
}

const fetchSubCategory = () => {

    return (dispatch) => {

        dispatch(getSubCategory())

        axios.get(`${API}/subCategory/v3`)
            .then(response => {
                dispatch(getSubCategorySucces(response.data))
            })
            .catch(function (error) {
                dispatch(getSubCategoryFailure(error.response.data.message))
            })
    }
}

export { actionGetSubCategory, actionGetSubCategorySucces, actionGetSubCategoryFailure, actionUpdateSubCategory, getSubCategory, getSubCategorySucces, getSubCategoryFailure, fetchSubCategory, updateSubCategory }
