import { axiosInstance } from '../../../utility'
import { API } from '../../../config/config'

const actionGetSettings = 'get_settings'
const actionGetSettingsSucces = 'get_settings_succes'
const actionGetSettingsFailure = 'get_settings_failure'
const actionUpdateSettings = 'update_settings'
const actionClearSettings = 'clear_settings'

const getSettings = () => {
    return {
        type: actionGetSettings
    }
}

const getSettingsSucces = (setting) => {

    return {
        type: actionGetSettingsSucces,
        payload: setting
    }
}

const getSettingsFailure = (error) => {
    return {
        type: actionGetSettingsFailure,
        payload: error

    }
}

const updateSettings = (data) => {
    return {
        type: actionUpdateSettings,
        payload: data
    }
}

const clearSettings = () => {
    return {
        type: actionClearSettings
    }
}

const fetchSettings = () => {

    return (dispatch) => {

        dispatch(getSettings())

        axiosInstance.get(`${API}/setting/v3/my-settings`)
            .then(response => {
                dispatch(getSettingsSucces(response.data))
            })
            .catch(function (error) {
                dispatch(getSettingsFailure(error.response.data.message))
            })
    }
}

export { actionGetSettings, actionGetSettingsSucces, actionGetSettingsFailure, actionUpdateSettings, actionClearSettings, getSettings, getSettingsSucces, getSettingsFailure, fetchSettings, updateSettings, clearSettings }
