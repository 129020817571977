import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IoMdNotificationsOutline } from 'react-icons/io'

import { socket } from '../../../../../socket'
import { toastify, toastsTypes, axiosInstance } from '../../../../../utility'

const NotificatiosnOperation = ({ profile, action }) => {

    const { t } = useTranslation()

    // eslint-disable-next-line
    const [notifNUmber, setnotifNUmber] = useState(0)

    const handleNotification = (payload) => {
        // console.log(payload);

        setnotifNUmber(prev => prev + 1)

        switch (payload.type) {
            case "like_an_article":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.appreciatedArticle')}`)
                break;
            case "follow_a_profile":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.startedFollowing')}`)
                break;
            case "article_deleted":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.articleDeletedFromFavorites')}`)
                break;
            case "article_updated":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.articleUpdatedInFavorites')}`)
                break;
            case "new_order":
                toastify(toastsTypes.info, `${t('notifications.new_order')} ${payload.userName}`)
                break;
            case "kyc_succeeded":
                toastify(toastsTypes.success, `${payload.userName} ${t('notifications.id_proof_accepted')}`)
                break;
            case "kyc_failed":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.id_proof_rejected')}`)
                break;
            case "tracking_order":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.order_update_notification')}`)
                break;
            case "new_payment_received":
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.new_payment_received')}`)
                break;
            case "new_review":
                toastify(toastsTypes.info, `${t('notifications.new_review_by')} ${payload.userName}`)
                break;
            default:
                toastify(toastsTypes.info, `${payload.userName} ${t('notifications.appreciatedArticle')}`)
                break;
        }
    }

    // fetch the unreaded notifs
    // eslint-disable-next-line
    const fetchUnreadedNotifications = async () => {
        var config = {
            method: 'get',
            url: `/notification/v3/unreadNotifications`,
        };

        await axiosInstance(config)
            .then(function (response) {
                setnotifNUmber(response.data.count);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        socket.on("receive_notification", handleNotification);

        fetchUnreadedNotifications()

        return () => {
            socket.off("receive_notification", handleNotification);
        };
        // eslint-disable-next-line
    }, []);



    return (
        <div
            className='cursor-pointer w-[30px] flex justify-center items-center opacity-80 transition duration-200 hover:opacity-100 active:opacity-60'
            onClick={() => {
                setnotifNUmber(0)
                action()
            }}
        >
            {
                profile.isLoading || !profile.data ?
                    <div className="min-w-[25px] min-h-[25px] rounded-full border animate-pulse bg-gray-200"></div>
                    :
                    <div className="relative">
                        {notifNUmber > 0 && (
                            <div className="absolute top-[-5px] left-[10px] min-w-[15px] min-h-[15px] flex justify-center items-start bg-red-500 rounded-md p-[2px]">
                                <span className='font-bold text-xs text-white'>{notifNUmber}</span>
                            </div>
                        )}
                        <IoMdNotificationsOutline size={27} color='#3f585f' />
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    };
};

export default connect(mapStateToProps, null)(NotificatiosnOperation);
