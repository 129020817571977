import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BsFacebook, BsInstagram, BsTiktok } from 'react-icons/bs';

import { aboutContent, socialMedia } from '../../utility'

import AppleStore from '../../assets/images/apple.svg'
import PlayStore from '../../assets/images/download.png'
import MangopayLogo from '../../assets/images/logo/Mangopay_logo.webp'

import './style.scss'

const Footer = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isWebView, setIsWebView] = useState(false);

    useEffect(() => {
        setIsWebView(new URLSearchParams(window.location.search).get('fromApp') === 'true');
        // eslint-disable-next-line
    });


    return (
        <footer className={`w-full flex justify-center items-center flex-col bg-concrete ${isWebView && 'hidden'}`}>
            <div className="w-full flex justify-center items-center flex-col desktops:w-9/12  small-screen:px-2 bg-concrete pt-[20px]">

                <div className="w-full grid grid-cols-4 gap-4 p-1 mobile:grid-cols-2">

                    {Object.keys(aboutContent).map((key) => (
                        <div key={key.toString()} className="flex justify-start items-start flex-col ">
                            <span className='text-base font-bold text-casal/60 p-[5px] ' >{t(`header.${key}`)}</span>
                            {
                                aboutContent[key].map((item, index) => {
                                    return (
                                        <span
                                            key={index.toString()}
                                            className='cursor-pointer text-sm text-casal min-w-full p-[5px] rounded transition duration-300 hover:bg-mercury active:opacity-70'
                                            onClick={() => {
                                                navigate(`${item.link}`)
                                            }}
                                        >
                                            {t(`pages.${item.name}`)}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    ))}

                </div>

                <div className="bar h-[1px] w-full bg-casal/30 mt-[20px]"></div>

                <div className="w-full flex justify-between items-center flex-row flex-wrap mobile:pt-4">
                    <div className="socialMedia flex justify-center items-center flex-row flex-nowrap mobile:w-full">
                        <div
                            className="ml-3 cursor-pointer opacity-75 transition duration-200 hover:opacity-100 active:opacity-60"
                            onClick={() => {
                                window.open(socialMedia.facebook, '_blank');
                            }}
                        >
                            <BsFacebook size={25} color='#3f585f' />
                        </div>
                        <div
                            className="ml-3 cursor-pointer opacity-75 transition duration-200 hover:opacity-100 active:opacity-60"
                            onClick={() => {
                                window.open(socialMedia.instagram, '_blank');
                            }}
                        >
                            <BsInstagram size={25} color='#3f585f' />
                        </div>
                        <div
                            className="ml-3 cursor-pointer opacity-75 transition duration-200 hover:opacity-100 active:opacity-60"
                            onClick={() => {
                                window.open(socialMedia.tiktok, '_blank');
                            }}
                        >

                            <BsTiktok size={25} color='#3f585f' />
                        </div>
                    </div>


                    <div className="stores flex justify-center items-center flex-row flex-nowrap mobile:w-full">
                        <div className="cursor-pointer mx-1 transition duration-200 active:opacity-70"
                            onClick={() => {
                                navigate('/PSPMANGOPAY')
                            }}
                        >
                            <img
                                className='w-[150px] bg-cover'
                                src={MangopayLogo}

                                alt="MangopayLogo"
                            />
                        </div>
                        <div className="cursor-pointer mx-1 transition duration-200 active:opacity-70"
                            onClick={null}
                        >
                            <img
                                className='w-[150px] bg-cover'
                                src={PlayStore}
                                alt="play store"
                            />
                        </div>
                        <div className="cursor-pointer mx-1 transition duration-200 active:opacity-70"
                            onClick={null}
                        >
                            <img
                                className='w-[150px] bg-cover'
                                src={AppleStore}
                                alt="app store"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer